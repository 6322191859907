import { PanelsApiFactory } from '@wix/blocks-widget-services/panels';
import { EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';
import { openElementsPanel } from './panel/elementsPanel';
import { FlowAPI } from '@wix/yoshi-flow-editor';
import {
  compRefIdSuffix,
  Panels,
  WidgetGfppTypes,
} from '../components/Consultants Widget/config/constants';

let COMP_REP;

export const registerEventListeners = async (
  editorSDK: EditorSDK,
  options: EditorReadyOptions,
  flowAPI: FlowAPI,
) => {
  const customPanelOptions = {
    editorSDK,
    editorType: options.origin.type,
    essentials: flowAPI.essentials,
  };
  const panelsApi = await new PanelsApiFactory().createPanelsApi(
    customPanelOptions,
  );

  await editorSDK.addEventListener('widgetGfppClicked', (event) => {
    const { id, componentRef } = event.detail;
    console.log('id:', id);
    switch (id) {
      case WidgetGfppTypes.DISPLAY_ELEMENTS:
        void openElementsPanel(editorSDK, componentRef, flowAPI);
        break;
      case WidgetGfppTypes.SELECT_SERVICE:
        console.log('Opening Connect to service');
        void panelsApi.openBlocksPanel('Connect to service', componentRef);
        break;
    }
  });
  await editorSDK.addEventListener('appVisitedInDashboard', (event) => {
    const { visitedAppDefIds } = event.detail;
    if (visitedAppDefIds[0] === Panels.SERVICES_PANEL_ID) {
      void panelsApi.openBlocksPanel('Connect to service', COMP_REP);
    }
  });
  await editorSDK.addEventListener('componentAddedToStage', async (event) => {
    const { compRef } = event.detail;
    let compRefId;
    if (!compRef.id.includes(compRefIdSuffix)) {
      compRefId = compRef.id + compRefIdSuffix;
      COMP_REP = { id: compRefId, type: compRef.type };
    }
    void panelsApi.openBlocksPanel('Connect to service', COMP_REP);
  });
};
