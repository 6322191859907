const COMPS_DATA = {
  serviceTitle: { role: 'serviceTitle', label: 'Title' },
  videoConferencing: {
    role: 'onlineBadge1',
    label: 'Video Conferencing Badge',
  },
  duration: { role: 'serviceDuration', label: 'Duration' },
  servicePrice: { role: 'servicePrice', label: 'Price' },
  staffMember: { role: 'staffName', label: 'Staff' },
  serviceLocation: { role: 'serviceLocation', label: 'Location' },
};

const CATEGORIES_IDS = {
  service: 'Service Deatails',
};

export const categoriesData = [
  {
    id: CATEGORIES_IDS.service,
    title: CATEGORIES_IDS.service,
  },
];

export const getElementsData = (flags) => [
  ...(flags.showVideoBadge
    ? [
        {
          label: COMPS_DATA.videoConferencing.label,
          identifier: { role: COMPS_DATA.videoConferencing.role },
          categoryId: CATEGORIES_IDS.service,
          index: 1,
        },
      ]
    : []),
  {
    label: COMPS_DATA.duration.label,
    identifier: { role: COMPS_DATA.duration.role },
    categoryId: CATEGORIES_IDS.service,
    index: 2,
  },
  {
    label: COMPS_DATA.servicePrice.label,
    identifier: { role: COMPS_DATA.servicePrice.role },
    categoryId: CATEGORIES_IDS.service,
    index: 3,
  },
  ...(!flags.staffMoreThanOne
    ? [
        {
          label: COMPS_DATA.staffMember.label,
          identifier: { role: COMPS_DATA.staffMember.role },
          categoryId: CATEGORIES_IDS.service,
          index: 4,
        },
      ]
    : []),
  ...(!flags.locationMoreThanOne
    ? [
        {
          label: COMPS_DATA.serviceLocation.label,
          identifier: { role: COMPS_DATA.serviceLocation.role },
          categoryId: CATEGORIES_IDS.service,
          index: 5,
        },
      ]
    : []),
];
