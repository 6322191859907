import { GetServiceResponse } from '@wix/ambassador-services-catalog-server/types';
import { ComponentRef, EditorSDK, WidgetProps } from '@wix/platform-editor-sdk';
import { getActiveSchedule } from '../../utils/mappers/serviceMapper';
import { categoriesData, getElementsData } from './elementsData';
import { Schedule } from '@wix/ambassador-services-catalog-server/http';

export const openElementsPanel = async (
  editorSDK: EditorSDK,
  widgetRef: ComponentRef,
  flowAPI,
): Promise<void> => {
  const { data }: WidgetProps =
    await editorSDK.application.appStudioWidgets.props.get('token', {
      widgetRef: { id: widgetRef.id, type: 'DESKTOP' },
    });
  let displayOptions = {
    locationMoreThanOne: false,
    staffMoreThanOne: false,
    showVideoBadge: true,
  };
  if (hasServiceId(data)) {
    const service: GetServiceResponse = await getServiceById(data, flowAPI);
    const activeSchedule: Schedule = getActiveSchedule(service);
    displayOptions = {
      locationMoreThanOne: activeSchedule!.availability!.locations!.length > 1,
      staffMoreThanOne: service!.resources!.length > 1,
      showVideoBadge: activeSchedule?.conferenceProvider?.providerId
        ? true
        : false,
    };
  }
  const getCollapsedRefComponentByRole = async (role) => {
    const [widgetRefHost] = await editorSDK.components.getAncestors('token', {
      componentRef: widgetRef,
    });
    const collapsedRefComponents =
      await editorSDK.components.refComponents.getCollapsedRefComponents(
        'token',
        {
          componentRef: widgetRefHost,
          // @ts-expect-error temp until types are GAed
          includeInnerCollapsed: true,
        },
      );
    const collapsedRefComponent = collapsedRefComponents.filter(
      (comp) => comp.role === role,
    );
    return collapsedRefComponent[0].componentRef;
  };

  const getCompToHide = async (componentRef) => {
    const type = await editorSDK.components.getType('token', { componentRef });
    return type.includes('AppWidget')
      ? (await editorSDK.components.getAncestors('t', { componentRef }))[0]
      : /* istanbul ignore next reason: we don't hide whole widget */ componentRef;
  };

  const showComp = async (componentRef) => {
    await editorSDK.components.refComponents.expandReferredComponent('token', {
      componentRef,
    });
    return editorSDK.application.livePreview.refresh('token', {
      shouldFetchData: false,
      source: 'AFTER_DB_CHANGE',
    });
  };

  const hideComp = async (componentRef) => {
    await editorSDK.components.refComponents.collapseReferredComponent(
      'token',
      {
        componentRef,
      },
    );
  };

  const addCompHandler = async ({ role }) => {
    const componentRef = await getCollapsedRefComponentByRole(role);
    return showComp(componentRef);
  };

  const removeCompHandler = async (compRef) => {
    const compToHide = await getCompToHide(compRef);
    return hideComp(compToHide);
  };

  const elementsData = getElementsData(displayOptions);

  return editorSDK.editor.openElementsPanel('t', {
    widgetRef,
    categoriesData,
    elementsData,
    addComponentHandler: addCompHandler,
    removeComponentHandler: removeCompHandler,
  });
};

const getServiceById = async (data, flowAPI) => {
  let responseService = {};
  try {
    const response = await flowAPI.httpClient.post(
      `/_api/services-catalog/v1/catalog/services/query`,
      {
        includeDeleted: false,
        query: {
          filter: {
            'service.id': { $eq: data.serviceId },
          },
        },
      },
    );
    responseService = await response.data.services[0];
  } catch (e) {
    console.error('Catalog data error: ', e);
  }
  return responseService;
};

const hasServiceId = (data) => {
  return data && data.serviceId;
};
