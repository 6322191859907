import { EditorSDK, EditorReadyOptions } from '@wix/platform-editor-sdk';

export const onEditorReady = async (
  editorSDK: EditorSDK,
  appDefId: string,
  options: EditorReadyOptions,
): Promise<void> => {
  if (options.firstInstall) {
  }
};
