import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
// import { FIELD_COMPONENT_TYPES } from '@wix/forms-common'

export const getWidgetManifest: GetWidgetManifestFn = (
  controllerBuilder,
  editorSDK,
  flow,
) => {
  controllerBuilder.set({
    displayName: 'Appointment Form',
    nickname: 'appointmentForm',
  });

  controllerBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    widgetDesignBuilder.set({
      title: 'Form Design',
      presetsTitle: 'Discover More Designs',
    });

    const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
    widgetDesignTabsBuilder
      .addTab((tabBuilder) => {
        tabBuilder.set({
          label: 'Input Fields',
        });
        tabBuilder.groups().set({
          compTypes: [
            'wysiwyg.viewer.components.inputs.TextInput',
            'wysiwyg.viewer.components.inputs.TextAreaInput',
            'wysiwyg.viewer.components.inputs.DatePicker',
            'wysiwyg.viewer.components.inputs.ComboBoxInput',
            'wysiwyg.viewer.components.inputs.Checkbox',
            'wixui.SignatureInput',
          ],
        });
      })
      .addTab((tabBuilder) => {
        tabBuilder.set({
          label: 'Book Button',
        });

        tabBuilder.groups().set({
          compTypes: ['wixui.StylableButton'],
        });
      })
      .addTab((tabBuilder) => {
        tabBuilder.set({
          label: 'Background',
        });

        tabBuilder.groups().set({
          roles: ['box1'],
        });
      });
  });
};
